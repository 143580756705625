<template>
  <footer>
    <div class="bg-white md:flex md:items-center md:justify-between shadow rounded-lg p-4 md:p-6 xl:p-8 my-6 mx-4">
      <ul class="flex">
        <li>
          <router-link
           to="/privacy"
           class="inline-block py-1 px-2 text-sm text-gray-500">
            プライバシー
          </router-link>
        </li>
        <li>
          <router-link
           to="/about"
           class="inline-block py-1 px-2 text-sm text-gray-500">
            データと安全性
          </router-link>
        </li>
        <li>
          <router-link
           to="/about"
           class="inline-block py-1 px-2 text-sm text-gray-500">
            運営者情報
          </router-link>
        </li>
      </ul>
    </div>
    <p class="text-center text-sm text-gray-400 pb-2">© {{new Date().getFullYear()}} kaisekit. All rights reserved. </p>
  </footer>
</template>

<script>
  export default {
    name: 'Footer',
  }
</script>

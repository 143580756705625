<template>
  <div class="flex py-48 justify-center items-center" v-if="initting">
    <Loading />
  </div>
  <div class="pt-6 px-4" v-else>
    <div class="my-16" v-if="!isSignedIn">
      <Login />
    </div>
    <div class="" v-else>
      <div class="flex py-48 justify-center items-center" v-if="initting">
        <Loading />
      </div>
      <div class="" v-else>
        <div class="w-full grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 gap-4">
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8  2xl:col-span-2">
            <WeeklyAccess />
          </div>
          <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 ">
            <TopDailyDomain :sitelist="tdSitelist" :date="date" :link="'/sites/'" />
          </div>
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 mt-4">
          <TopQuery :querylist="querylist" :date="date" />
        </div>
        <div class="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 mt-4">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "../db";
import Loading from '@/components/Atom/Loading'
import TopDailyDomain from '@/components/Widgets/TopDailyDomain'
import WeeklyAccess from '@/components/Widgets/WeeklyAccess'
import TopQuery from '@/components/Widgets/TopQuery'
import Login from '@/components/Pages/Login'

export default {
  name: 'Home',
  data() {
    return {
      tdSitelist: [],
      querylist: [],
      date: '',
      loading: true
    };
  },
  setup() {
    return {
      db,
    };
  },
  components: {
    Loading,
    TopDailyDomain,
    Login,
    WeeklyAccess,
    TopQuery,
  },
  props: ['initting', 'isSignedIn'],
  watch: {
    initting: function(status){
      return status;
    }
  },
  async mounted(){
    await this.getTopDailyDomainData();
    await this.getTopQueryData();
    this.loading = false;
  },
  methods: {
    async getTopDailyDomainData(){
      const lastscs = await this.db.scs.reverse().sortBy('date');
      if(lastscs.length > 0){
        const date = lastscs[0].date;
        this.date = this.$dayjs(date).format("YYYY/M/D (ddd)");
        const domains = await this.db.sites.where({"type": 1}).toArray();
        // console.log(sites);
        const tmplist = [];
        for(let d in domains){
          const tmp = {};
          tmp.sid = domains[d].name;
          tmp.name = domains[d].name;

          const summary = await this.db.scs.where(
            {
            "sid": domains[d].id,
            "date": date
            }
          ).first();
          if(summary !== undefined){
            tmp.clk = summary.clk;
            tmp.ctr = summary.ctr;
            tmp.imp = summary.imp;
            tmp.pos = summary.pos;
          }
          tmplist.push(tmp);
        }
        tmplist.sort((a, b) => b.clk - a.clk);
        this.tdSitelist = tmplist.slice(0, 10);

      }
    },
    async getTopQueryData(){
      const lastscs = await this.db.scq.orderBy('date').last();
      const siteList = await db.sites.toArray();
      const list = await this.db.scq.where("date").equals(lastscs.date).reverse() .sortBy('clk').then((value) => {
        let cnt = 0;
        const result = [];
        for(let i in value){
          cnt++;
          if(cnt < 31){
            result.push(value[i])
          }else{
            break;
          }
        }
        return result;
      });

      for(let l in list){
        const tmp = list[l];
        tmp.site = siteList.filter(d => d.id === list[l].sid)[0].name;
        this.querylist.push(tmp);
      }

    }
    // onDayClick(day) {
    //   const idx = this.days.findIndex(d => d.id === day.id);
    //   if (idx >= 0) {
    //     this.days.splice(idx, 1);
    //   } else {
    //     this.days.push({
    //       id: day.id,
    //       date: day.date,
    //     });
    //   }
    // },
  }
}
</script>

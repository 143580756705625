import { createApp } from 'vue'
import App from './App.vue'
import { store } from './stores/'
import VueGapi from 'vue-gapi'
import routes from './routes'
import 'windi.css'
// import { SetupCalendar, Calendar, DatePicker } from 'v-calendar';
import dayjs from 'dayjs';
import SetInterval from './setInterval.js'

const app = createApp(App)

app.use(VueGapi, {
  apiKey: 'AIzaSyAsFzQBe_uwXve4inLzbegrh1fbQy3_bWI',
  clientId: '147148011830-5tcf34k9gctjg9acij437io859um4tdr.apps.googleusercontent.com',
  discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"],
  scope: 'https://www.googleapis.com/auth/webmasters.readonly https://www.googleapis.com/auth/drive.appdata',
})

app.config.globalProperties.$filters = {
  numf(number) {
    return Number(number).toLocaleString("en-US", {
      maximumFractionDigits: 20
    })
  },
  async sleep(time) {
    return new Promise( resolve => setTimeout(resolve, time) );
  }
};

app.use(routes);
app.use(store);
app.use(SetInterval);
app.config.globalProperties.$dayjs = dayjs

// v-calener
// app.use(SetupCalendar, {})
// // Use the components
// app.component('Calendar', Calendar)
// app.component('DatePicker', DatePicker)

app.mount('#app')

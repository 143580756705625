<template>
  <div class="mb-4 flex items-center justify-between">
    <div>
      <h3 class="text-xl font-bold text-gray-900 mb-2">Top Daliy (Domain)</h3>
      <span class="text-base font-normal text-gray-500">{{date}}</span>
    </div>
    <div class="flex-shrink-0">
      <router-link :to="link" class="text-sm font-medium text-cyan-600 hover:bg-gray-100 rounded-lg p-2">View all</router-link>
    </div>
  </div>
  <!-- Table -->
  <div class="flex flex-col mt-8">
    <div class="overflow-x-auto rounded-lg">
      <div class="align-middle inline-block min-w-full">
        <div class="shadow overflow-hidden sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Site Name
                </th>
                <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Clicks
                </th>
                <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Views
                </th>
                <th scope="col" class="p-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  CTR
                </th>
              </tr>
            </thead>
            <tbody class="bg-white">
              <tr v-for="(site, si) in sitelist" :key="'si-' + si">
                <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-900">
                  <router-link :to="'/sites/' + site.name + '/'">
                    <span
                     v-if="site.type === 1"
                     class="bg-gray-200 text-gray-800 mr-3 text-sm font-medium inline-flex items-center justify-center px-2 rounde------md">Domain</span>
                    <span class="font-semibold">{{site.name}}</span>
                  </router-link>
                </td>
                <td class="p-4 whitespace-nowrap text-sm font-normal text-gray-500">
                  {{$filters.numf(site.clk)}}
                </td>
                <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                  {{$filters.numf(site.imp)}}
                </td>
                <td class="p-4 whitespace-nowrap text-sm font-semibold text-gray-900">
                  {{(site.ctr * 100).toFixed(2)}}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TopDailyDomain',
  data() {
    return {
    }
  },
  props: ['sitelist', 'date', 'link'],
  mounted(){
  },
  methods: {
  }
}
</script>

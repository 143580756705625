<template>
  <div class="flex items-center justify-between mb-4">
    <div class="flex-shrink-0">
      <span class="text-2xl sm:text-3xl leading-none font-bold text-gray-900">{{totalclk}}</span>
      <h3 class="text-base font-normal text-gray-500">Access this week</h3>
    </div>
    <div class="flex items-center justify-end flex-1 text-green-500 text-base font-bold">
      {{compare}}%
      <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M5.293 7.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L6.707 7.707a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
      </svg>
    </div>
  </div>
  <div id="main-chart" style="min-height: 435px;">
    <div class="apexcharts-canvas apexcharts45jb0zotf apexcharts-theme-light">
      <LineChartWidgets :labels="labels" :datasets="datasets" />
      <table class="table-fixed mt-8 w-full border-1">
        <thead>
          <tr class=" bg-gray-100">
            <th>-</th>
            <th
             v-for="(d, di) in labels" :key="'di-'+di"
             class="text-sm font-bold p-3"
             >
               {{d}}
             </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(data, dti) in datasets" :key="'dti-'+dti">
            <td class="p-2 text-sm">{{data.label}}</td>
            <td class="p-2" v-for="(dd, ddi) in data.data" :key="'ddi-'+ddi">{{$filters.numf(dd)}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import LineChartWidgets from '@/components/Widgets/LineChartWidgets';
import { db } from "../../db";

export default{
  name: 'WeeklyAccess',
  data() {
    return {
      labels: [],
      datasets: [],
      totalclk: 0,
      lastTotalclk: 0,
      compare: 0,
    };
  },
  setup() {
    return {
      db,
    };
  },
  components: {
    LineChartWidgets
  },
  created(){
    this.buildChartdata();
  },
  methods:{
    async buildChartdata(){
      const lastscs = await this.db.scs.reverse().sortBy('date');
      if(lastscs.length > 0){
        const lastdate = lastscs[0].date;
        const maxdays = 7;
        let cnt = 0;
        while (cnt < maxdays) {
          this.labels.push(this.$dayjs(lastdate).subtract(cnt, 'day').format('M/D (ddd)'))
          cnt++;
        }
        this.labels.reverse();

        const weeks = [
          {
            'lastdate': lastdate,
            'maxdays': maxdays,
            'label': 'This Week',
            'backgroundColor': '#FF3300',
            'thisweek': true,
          },
          {
            'lastdate': this.$dayjs(lastdate).subtract(7, 'day').format('YYYY-MM-DD'),
            'maxdays': maxdays,
            'label': 'Last Week',
            'backgroundColor': '#CCC',
            'thisweek': false,
          }
        ];

        for(let w in weeks){
          const dataset = await this.buildDataset(weeks[w].lastdate, weeks[w].maxdays, weeks[w].label, weeks[w].backgroundColor, weeks[w].thisweek);
          this.datasets.push(dataset)
        }
        // console.log((this.totalclk / this.lastTotalclk));
        this.compare = ((this.totalclk / this.lastTotalclk) * 100 - 100).toFixed(2);
      }
    },
    async buildDataset(lastdate, maxdays, label, backgroundColor, thisweek){
      let cnt = 0;
      const dataset = {};
      dataset.label = label;
      dataset.backgroundColor = [backgroundColor];
      dataset.data = [];

      const checkdates = [];
      while (cnt < maxdays) {
        const date = this.$dayjs(lastdate).subtract(cnt, 'day').format('YYYY-MM-DD');
        checkdates.push(date);
        cnt++;
      }
      checkdates.reverse();

      // this week
      for(let c in checkdates){
        const weeklydata = await this.db.scs.where({'date': checkdates[c].replace(/-/g, '')}).toArray();

        const count = weeklydata.reduce(function(sum, el){return sum + el.clk;}, 0);
        if(thisweek){
          this.totalclk += count;
          dataset.borderWidth = 7;
        }else{
          this.lastTotalclk += count;
        }
        dataset.data.push(count);
      }
      return dataset;
    }
  }
};
</script>

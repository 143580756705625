import { createStore } from 'vuex'

export const store = createStore({
  state () {
    return {
      msg: '',
      msgBox: false
    }
  },
  mutations: {
    setMsg (state, val) {
      state.msg = val;
    },
    setmsgBox (state, val) {
      state.msgBox = val;
    }
  },
  actions: {
    setMsg (context ,val) {
      // console.log(val);
      context.commit('setMsg', val)
    },
    setmsgBox (context ,val) {
      context.commit('setmsgBox', val)
    }
  }
})

<template>
  <div
   class="z-50 w-full text-center transition-all duration-200 ease-linear fixed"
   :class="{'top-0 mt-2': msgBox, '-top-12 -mt-2': !msgBox}"
   >
    <div class="w-sm py-3 px-4 text-sm text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800 mx-auto" role="alert">
      {{msg}}
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name:'Message',
  setup () {
    const store = useStore()
    return {
      msg: computed(() => store.state.msg),
      msgBox: computed(() => store.state.msgBox),
    }
  },
}
</script>

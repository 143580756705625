import Dexie from "dexie";

export const db = new Dexie("kaisekit-analytics");

db.version(2).stores(
  {
    sites: "++id, name, type, fetched_at",
    operations: "++id, fetched_at",
    scq: "[sid+date+w], [sid+date], sid, date, w, clk, ctr, imp, pos", // key = sid-date-w
    scp: "[sid+date+page], [sid+date], sid, date, page, clk, ctr, imp, pos", // key = sid-date-page
    scs: "[sid+date], date, clk, ctr, imp, pos"
  }
);

db.version(3).stores(
  {
    sites: "++id, name, type, fetched_at",
    operations: "++id, fetched_at",
    scq: "[sid+date+w], [sid+date], sid, date, w, clk, ctr, imp, pos", // key = sid-date-w
    scp: "[sid+date+page], [sid+date], sid, date, page, clk, ctr, imp, pos", // key = sid-date-page
    scs: "[sid+date], date, clk, ctr, imp, pos", // key = sid-date
    queries: "[sid+w], sid, w" // key = sid-date
  }
);


db.version(4).stores(
  {
    sites: "++id, name, type, fetched_at",
    operations: "++id, fetched_at",
    scq: "[sid+date+w], [sid+date], sid, date, w, clk, ctr, imp, pos", // key = sid-date-w
    scp: "[sid+date+page], [sid+date], sid, date, page, clk, ctr, imp, pos", // key = sid-date-page
    scs: "[sid+date], date, clk, ctr, imp, pos", // key = sid-date
    queries: "++id, [sid+w], sid, w" // key = sid-date
  }
);

import { createRouter, createWebHistory } from 'vue-router'
import Home from './views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path:"/sites",
    component: () => import('./views/Sites.vue')
  },
  {
    path:"/sites/:id",
    component: () => import('./views/SiteDetail.vue')
  },
  {
    path:"/sites/:id/checker/",
    component: () => import('./views/Checker.vue')
  },
  {
    path:"/checker",
    component: () => import('./views/Checker.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('./views/About.vue')
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('./views/Privacy.vue')
  },
]

const router = createRouter({
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

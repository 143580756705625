<template>
  <LineChart :chartData="chartData" :options="options" />
</template>

<script>
import { defineComponent } from 'vue';
import { LineChart } from 'vue-chart-3';
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default defineComponent({
  name: 'LineChartWidgets',
  components: { LineChart },
  props: ['labels', 'datasets', 'options'],
  setup(props) {
    const chartData = {
      labels: props.labels,
      datasets:
      props.datasets,
      options:
      props.options
    };
    return {
      chartData,
    };
  },
});
</script>

<template>
    <a href="/" class="text-2xl font-semibold flex justify-center items-center mb-8 lg:mb-10">
      <img src="icon.png" class="h-6 mr-2" alt="kaisekit Analytics Logo">
      <span class="self-center whitespace-nowrap">Analytics</span>
    </a>
    <!-- Card -->
    <div class="mx-auto bg-white shadow rounded-lg md:mt-0 w-full sm:max-w-screen-sm xl:p-0">
        <div class="p-6 sm:p-8 lg:p-16 space-y-8">
            <h2 class="text-2xl lg:text-3xl font-bold text-gray-900 text-center">
                Sign in
            </h2>
            <div class="text-center">
              <button type="submit" class="text-white bg-cyan-600 hover:bg-cyan-700 focus:ring-4 focus:ring-cyan-200 font-medium rounded-lg text-base px-5 py-3 w-full sm:w-auto text-center">Login with Google</button>
            </div>
            <p class="text-sm text-gray-500">kasekit Analyticsは現在、Googleアカウントログインのみの対応となっています。</p>
        </div>
    </div>
</template>

<template>
  <Header :isSignedIn="isSignedIn" :usename="usename" />
  <div class="flex overflow-hidden bg-white pt-16">
    <Menus />
    <div class="h-full w-full min-h-screen bg-gray-50 relative overflow-y-auto lg:ml-64">
      <router-view :initting="initting" :isSignedIn="isSignedIn"/>
      <Footer />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { db } from "./db";
// import 'v-calendar/dist/style.css';
import Header from '@/components/Frame/Header'
import Menus from '@/components/Frame/Menus'
import Footer from '@/components/Frame/Footer'
// import {importDB, exportDB, importInto, peakImportFile} from "dexie-export-import";
import {exportDB} from "dexie-export-import";
// import blobCompare from 'blob-compare';

// const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) );

export default {
  name: 'App',
  data() {
    return {
      isSignedIn: null,
      usename: '',
      initting: true
    }
  },
  provide() {
    return {
      initting: this.initting,
      isSignedIn: this.isSignedIn
    }
  },
  components: {
    Header,
    Menus,
    Footer,
  },
  setup() {
    const store = useStore()
    const setMsg = (val) => store.dispatch('setMsg', val);
    const setmsgBox = (val) => store.dispatch('setmsgBox', val);
    return {
      db,
      setMsg,
      setmsgBox
    };
  },
  async created() {
    document.body.classList.add('bg-gray-50')
  },
  async mounted(){
    // console.log('app mounted');
    this.setmsgBox(true);
    this.setMsg('スタート処理をします');
    this.setMsg('ログイン状態を確認します');
    await this.$gapi.listenUserSignIn(async (isSignedIn) => {
      if(isSignedIn){
        this.isSignedIn = isSignedIn
        const user = await this.$gapi.getCurrentUser();
        this.usename = user.getBasicProfile().getName();

        // Gdrive同期チェック
        await this.syncGDrive();

        // setup
        await this.setUp();

        // polling
        this.$setInterval(async() => {
          await this.setUp();
        }, 3600000)
      }else{
        this.setmsgBox(false);
      }
    })

    this.initting = false;
  },
  methods: {
    async syncGDrive(){
      this.setMsg('Google Driveとの同期を行います');
      await this.$filters.sleep(500);

      const gapi = await this.$gapi.getGapiClient();
      const params = {
        spaces: "appDataFolder",
        fields: "nextPageToken, files(id, name, modifiedTime, size, description)",
        pageSize: 100,
        orderBy: "modifiedTime desc",
      };
      this.setMsg('Google Driveのバックアップデータを確認しています');
      const gdCheck = await gapi.client.drive.files.list(params);
      const gdAppfile = JSON.parse(gdCheck.body).files[0];
      // const tmp = JSON.parse(gdCheck.body).files;
      // console.log(tmp);
      // // for(let t in tmp){
      // //   console.log(tmp[t].id);
      // //   await gapi.client.drive.files.delete({'fileId': tmp[t].id});
      // // }
      // console.log(blob);


      if(gdAppfile === undefined){
        this.setMsg('Google Driveに新規バックアップを作成します');
        const dbFile = await gapi.client.drive.files.create({
          'parents': ['appDataFolder'],
          'name': 'db.json',
          'mimeType': 'application/json',
          'description': `${this.$dayjs().unix()}`
        });
        const blob = await exportDB(db);
        const convData = await blob.text()

        await gapi.client.request({
          path: '/upload/drive/v3/files/' + dbFile.result.id,
          method: 'PATCH',
          params: {
            uploadType: 'media'
          },
          name: "db.json",
          body: convData,
          fields: 'content',
          headers: { 'content-type': 'multipart/form-data' }
        });
        // console.log(data);
        this.setMsg('Google Driveにバックアップを作成しました');
        await this.$filters.sleep(500);
      }else{
        this.setMsg('Google Driveのバックアップとの差分を確認します');
        await this.$filters.sleep(1000);

        // const gDdata = await gapi.client.drive.files.get({fileId: gdAppfile.id, alt: "media"});
        const blob = await exportDB(db);
        const convData = await blob.text();
        //
        await gapi.client.request({
          path: '/upload/drive/v3/files/' + gdAppfile.id,
          method: 'PATCH',
          params: {
            uploadType: 'media'
          },
          body: convData,
        });
        console.log('upload done');
        // const newBlob = new Blob([gDdata.body], { type: "text/json" });
        // const compare = await blobCompare.isEqual(blob, newBlob);
        // console.log(compare);
        // if(compare){
        //   await db.delete();
        //   await importDB(newBlob);
        //   this.$router.go({path: this.$router.currentRoute.path, force: true})
        // }
        // await importDB(new Blob([dbData], { type: "text/plan" }));
        this.setMsg('Google Driveにローカルデータをアップロードしました。');
        await this.$filters.sleep(500);
      }
    },
    async setUp(){
      this.setMsg('スタート処理を行います');
      this.setmsgBox(true);

      this.setMsg('Google Search Consoleのデータ同期を行います');
      await this.$filters.sleep(500);

      const op = await this.db.operations.toArray();
      const slterm = this.$dayjs().subtract(15, 'minute').unix();

      // site check
      const sitecheckTime = op.filter(x => x.id === 'sitelist');
      if(sitecheckTime.length === 0){
        await this.syncSite();
      }else{
        if(sitecheckTime[0].fetched_at < slterm){
          await this.syncSite();
        }
      }

      // scq check
      // const scqterm = this.$dayjs().subtract(30, 'second').unix();
      const scqterm = this.$dayjs().subtract(12, 'hour').unix();
      const scqTime = op.filter(x => x.id === 'sc_query');
      if(scqTime.length === 0){
        await this.syncSCQuery();
        await this.$filters.sleep(1000);
        this.setmsgBox(false);
      }else{
        if(scqTime[0].fetched_at < scqterm){
          await this.syncSCQuery();
          this.setMsg('データ更新が完了しました。ページをリロードします。');
          await this.$filters.sleep(1000);
          this.setmsgBox(false);
          await this.$filters.sleep(1000);
          this.$router.go({path: this.$router.currentRoute.path, force: true})
        }else{
          this.setMsg('データは最新です。同期をスキップします。');
          await this.$filters.sleep(1000);
          this.setmsgBox(false);
        }
      }
    },
    async syncSite(){
      this.setMsg('サイト一覧の同期を行います');
      await this.$filters.sleep(1000);
      const gapi = await this.$gapi.getGapiClient();

      const sitelist = await gapi.client.request({path: "https://www.googleapis.com/webmasters/v3/sites",})
      const sl = JSON.parse(sitelist.body).siteEntry;
      for (let i in sl) {
        const tmp = {};
        const id = await this.db.sites.where({"name": sl[i].siteUrl.replace(/sc-domain:/, '')}).first();
        if(id === undefined){
          // 1 = sc:domain, 2 = url
          if(sl[i].siteUrl.match(/sc-domain:/)){
            tmp.type = 1;
            tmp.name = sl[i].siteUrl.replace(/sc-domain:/, '');
            await this.db.sites.put(tmp);
          }
        }
      }

      // op updated
      await this.db.operations.put({id: 'sitelist', 'fetched_at': this.$dayjs().unix()});
      await this.$filters.sleep(1000);
      this.setMsg('サイト一覧の同期が完了しました');
    },
    async syncSCQuery(){
      this.setMsg('Search Consoleデータの同期を開始します');

      const maxdays = 17;
      let cnt = 3;
      while (cnt < maxdays) {
        cnt++;
        const queryDate = this.$dayjs().subtract(cnt, 'day').format('YYYY-MM-DD');

        // データがすでにあるかチェック
        const check = await this.db.scs.where({"date": queryDate.replace(/-/g, '')}).last();
        // console.log(check);

        // ない時だけ対処
        if(check === undefined){
          this.setMsg(queryDate + 'のデータを同期しています...');
          await this.getSCQuery(queryDate)
          await this.$filters.sleep(5000);
        }
      }

      // op updated
      await this.db.operations.put({id: 'sc_query', 'fetched_at': this.$dayjs().unix()});
    },
    async getSCQuery(queryDate){
      const date = queryDate.replace(/-/g, '');
      console.log(date);
      const sl = await db.sites.toArray();
      const bulkQdata = [];
      const bulkPdata = [];
      const bulkSdata = [];

      // gapi init
      const gapi = await this.$gapi.getGapiClient();

      // site
      for(let s in sl){
        // query
        const sckey = (sl[s].type === 1)? 'sc-domain:' + sl[s].name: sl[s].name;
        const qData = await gapi.client.request({
          path: `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(sckey)}/searchAnalytics/query`,
          method: "POST",
          body: {
            'startDate': queryDate,
            'endDate': queryDate,
            'dimensions': ['QUERY'],
          }
        })
        const qrows = qData.result.rows;
        if(qrows !== undefined){
          for(let d in qrows){
            bulkQdata.push({
              "sid": sl[s].id,
              "date": date,
              "w": qrows[d].keys[0],
              "clk": qrows[d].clicks,
              "ctr": qrows[d].ctr,
              "imp": qrows[d].impressions,
              "pos": qrows[d].position,
            })
          }
        }

        await this.$filters.sleep(1000);
        // page
        const pData = await gapi.client.request({
          path: `https://www.googleapis.com/webmasters/v3/sites/${encodeURIComponent(sckey)}/searchAnalytics/query`,
          method: "POST",
          body: {
            'startDate': queryDate,
            'endDate': queryDate,
            'dimensions': ['PAGE'],
          }
        })
        const prows = pData.result.rows;
        if(prows !== undefined){
          for(let d in prows){
            bulkPdata.push({
              "sid": sl[s].id,
              "date": date,
              "page": prows[d].keys[0],
              "clk": prows[d].clicks,
              "ctr": prows[d].ctr,
              "imp": prows[d].impressions,
              "pos": prows[d].position,
            })
          }
          // summary
          bulkSdata.push({
            "sid": sl[s].id,
            "date": date,
            "clk": prows.reduce((prev, current) => { return prev + current.clicks}, 0),
            "imp": prows.reduce((prev, current) => { return prev + current.impressions}, 0),
            "ctr": (prows.reduce((prev, current) => { return prev + current.ctr}, 0)) / prows.length,
            "pos": (prows.reduce((prev, current) => { return prev + current.position}, 0)) / prows.length,
          });
        }else{
          bulkSdata.push({
            "sid": sl[s].id,
            "date": date,
            "clk": 0,
            "imp": 0,
            "ctr": 0,
            "pos": 0,
          });
        }
        await this.$filters.sleep(1000);
      }
      // console.log(bulkQdata);
      // console.log(bulkPdata);
      // console.log(bulkSdata);
      await db.scq.bulkPut(bulkQdata);
      await db.scp.bulkPut(bulkPdata);
      await db.scs.bulkPut(bulkSdata);
    }
  },
}
</script>
